import { WithId } from 'django-rest-react'

export interface Displayable extends WithId {
  display: string
}


export const PERMESSI: Displayable[] = [
  { id: 'a', display: 'Admin' },
  { id: 'm', display: 'Moderatore' },
  { id: 's', display: 'Smistatore' },
  { id: 'e', display: 'Evento' },
  { id: 'b', display: 'Base' },
  { id: 'x', display: 'Esterno' },
]


export const FLAGS_SMISTATORE: Displayable[] = [
  { id: 'b', display: 'Buono' },
  { id: 'r', display: 'Da rivedere' },
  { id: 's', display: 'Scartato' },
  { id: 'd', display: 'Duplicato' },
]


export const STATO_SHORTLIST: Displayable[] = [
  { id: 'r', display: "Da rivedere" },
  { id: 'a', display: "Approvata" },
  { id: 'p', display: "Problemi da controllare" },
]

export const FONTE: Displayable[] = [
  { id: 'sen', display: "Vecchio Senigallia" },
  { id: 'ipho', display: "Vecchio IPhO" },
  { id: 'apho', display: "Vecchia APhO" },
  { id: 'libro', display: "Libro di testo (specificare)" },
  { id: 'orig', display: "Originale, inventato da te da zero" },
  { id: 'altro', display: "Altro" },
]
