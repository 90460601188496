import * as T from './auto-types';
import {
   get_paginated_json, post_page, put_page, patch_page, delete_page,
   get_single_json, plain_object,
} from 'django-rest-react';

export type DeepPartial<T> = T extends object ? {
  [P in keyof T]?: DeepPartial<T[P]>;
} : T;


export const delete_bozza_problema = async (pk: number) => delete_page<T.BozzaProblemaForm & { id: number }>(`/api/problems/problemi/bozze/${pk}/`)
export const delete_soluzione_con_risposta = async (pk: number) => delete_page<T.SoluzioneConRispostaForm & { id: number }>(`/api/problems/problemi/soluzione/${pk}/`)
interface FilterAllegato {
partecipante?: number,
}
export const get_allegato = async (filter: FilterAllegato) => get_paginated_json<T.Allegato & { id: number }>(`/api/problems/allegato/`, filter as unknown as plain_object)
export const get_allegato_detail = async (pk: number) => get_single_json<T.Allegato & { id: number }>(`/api/problems/allegato/${pk}/`)
interface FilterAssegnazioneProblema {
data__gte?: string,
assegnatore?: number,
assegnato?: number,
problema?: number,
}
export const get_assegnazione_problema = async (filter: FilterAssegnazioneProblema) => get_paginated_json<T.AssegnazioneProblema & { id: number }>(`/api/problems/problemi/assegnazione/`, filter as unknown as plain_object)
interface FilterAssegnazioneProblemaEvento {
data__gte?: string,
assegnatore?: number,
evento?: number,
problema?: number,
}
export const get_assegnazione_problema_evento = async (filter: FilterAssegnazioneProblemaEvento) => get_paginated_json<T.AssegnazioneProblemaEvento & { id: number }>(`/api/problems/problemi/assegnazione-evento/`, filter as unknown as plain_object)
export const get_bozza_problema = async () => get_paginated_json<T.BozzaProblema & { id: number }>(`/api/problems/problemi/bozze/`)
export const get_bozza_problema_detail = async (pk: number) => get_single_json<T.BozzaProblema & { id: number }>(`/api/problems/problemi/bozze/${pk}/`)
export const get_categoria = async () => get_paginated_json<T.Categoria & { id: number }>(`/api/problems/categoria/`)
export const get_categoria_detail = async (pk: number) => get_single_json<T.Categoria & { id: number }>(`/api/problems/categoria/${pk}/`)
export const get_categoria_evento = async () => get_paginated_json<T.CategoriaEvento & { id: number }>(`/api/problems/problemi/categoriaevento/`)
export const get_categoria_evento_detail = async (pk: number) => get_single_json<T.CategoriaEvento & { id: number }>(`/api/problems/problemi/categoriaevento/${pk}/`)
interface FilterCitazioneEvento {
data__gte?: string,
evento?: number,
partecipante?: number,
}
export const get_citazione_evento = async (filter: FilterCitazioneEvento) => get_paginated_json<T.CitazioneEvento & { id: number }>(`/api/problems/problemi/citazioni-evento/`, filter as unknown as plain_object)
interface FilterCitazionePartecipante {
data__gte?: string,
persona?: number,
partecipante?: number,
}
export const get_citazione_partecipante = async (filter: FilterCitazionePartecipante) => get_paginated_json<T.CitazionePartecipante & { id: number }>(`/api/problems/problemi/citazioni-partecipante/`, filter as unknown as plain_object)
interface FilterCitazioneThread {
data__gte?: string,
thread?: number,
partecipante?: number,
}
export const get_citazione_thread = async (filter: FilterCitazioneThread) => get_paginated_json<T.CitazioneThread & { id: number }>(`/api/problems/problemi/citazioni-thread/`, filter as unknown as plain_object)
interface FilterCommento {
partecipante?: number,
data__gte?: string,
thread?: number,
}
export const get_commento = async (filter: FilterCommento) => get_paginated_json<T.Commento & { id: number }>(`/api/problems/problemi/comments/`, filter as unknown as plain_object)
interface FilterCommento {
thread?: number,
partecipante?: number,
data__gte?: string,
}
export const get_commento_2 = async (filter: FilterCommento) => get_paginated_json<T.Commento & { id: number }>(`/api/problems/shortlist/comments/`, filter as unknown as plain_object)
export const get_commento_detail = async (pk: number) => get_single_json<T.Commento & { id: number }>(`/api/problems/problemi/comments/${pk}/`)
export const get_commento_detail_2 = async (pk: number) => get_single_json<T.Commento & { id: number }>(`/api/problems/shortlist/comments/${pk}/`)
interface FilterCommentoVersionatoFeed {
data__gte?: string,
partecipante?: number,
commento__thread__problema?: number,
commento__thread?: number,
}
export const get_commento_versionato_feed = async (filter: FilterCommentoVersionatoFeed) => get_paginated_json<T.CommentoVersionatoFeed & { id: number }>(`/api/problems/problemi/commenti-feed/`, filter as unknown as plain_object)
export const get_evento = async () => get_paginated_json<T.Evento & { id: number }>(`/api/problems/evento/`)
export const get_evento_detail = async (pk: number) => get_single_json<T.Evento & { id: number }>(`/api/problems/evento/${pk}/`)
export const get_flag_smistatore_detail = async (pk: number) => get_single_json<T.FlagSmistatore & { id: number }>(`/api/problems/problemi/flags/${pk}/`)
export const get_partecipante = async () => get_paginated_json<T.Partecipante & { id: number }>(`/api/problems/partecipante/`)
export const get_partecipante_contributor = async () => get_paginated_json<T.PartecipanteContributor & { id: number }>(`/api/problems/problemi/contributors/`)
export const get_partecipante_detail = async (pk: number) => get_single_json<T.Partecipante & { id: number }>(`/api/problems/partecipante/${pk}/`)
interface FilterProblemaVersionatoFeed {
data__gte?: string,
autore?: number,
thread?: number,
}
export const get_problema_versionato_feed = async (filter: FilterProblemaVersionatoFeed) => get_paginated_json<T.ProblemaVersionatoFeed & { id: number }>(`/api/problems/problemi/problemi-feed/`, filter as unknown as plain_object)
interface FilterRispostaConRisposta {
problema?: number,
}
export const get_risposta_con_risposta = async (filter: FilterRispostaConRisposta) => get_paginated_json<T.RispostaConRisposta & { id: number }>(`/api/problems/problemi/mia-risposta/`, filter as unknown as plain_object)
export const get_shortlist_detail = async (pk: number) => get_single_json<T.Shortlist & { id: number }>(`/api/problems/shortlist/versione/${pk}/`)
export const get_soluzione_export_detail_export_risposte = async (pk: number) => get_single_json<T.SoluzioneExport & { id: number }>(`/api/problems/shortlist/${pk}/export_risposte/`)
export const get_task = async () => get_single_json<T.Task & { id: number }>(`/api/owlitrack/last_cron/`)
export const get_thread_shortlist = async () => get_paginated_json<T.ThreadShortlist & { id: number }>(`/api/problems/shortlist/`)
export const get_thread_shortlist_detail = async (pk: number) => get_single_json<T.ThreadShortlist & { id: number }>(`/api/problems/shortlist/${pk}/`)
export const get_thread_shortlist_detail_export = async (pk: number) => get_single_json<T.ThreadShortlist & { id: number }>(`/api/problems/shortlist/${pk}/export/`)
interface FilterVotoBellezza {
problema?: number,
partecipante?: number,
}
export const get_voto_bellezza = async (filter: FilterVotoBellezza) => get_paginated_json<T.VotoBellezza & { id: number }>(`/api/problems/problemi/voto-bellezza/`, filter as unknown as plain_object)
interface FilterVotoDifficolta {
problema?: number,
partecipante?: number,
}
export const get_voto_difficolta = async (filter: FilterVotoDifficolta) => get_paginated_json<T.VotoDifficolta & { id: number }>(`/api/problems/problemi/voto-difficolta/`, filter as unknown as plain_object)
export const get_whoami = async () => get_single_json<T.Whoami & { id: number }>(`/api/whoami/`)
export const patch_assegna_problema = async (pk: number, data: DeepPartial<T.AssegnaProblemaForm>) => patch_page<T.AssegnaProblemaForm & { id: number }>(`/api/problems/problemi/assegna/${pk}/`, data as unknown as plain_object)
export const patch_bozza_problema = async (pk: number, data: DeepPartial<T.BozzaProblemaForm>) => patch_page<T.BozzaProblemaForm & { id: number }>(`/api/problems/problemi/bozze/${pk}/`, data as unknown as plain_object)
export const patch_commento = async (pk: number, data: DeepPartial<T.CommentoForm>) => patch_page<T.CommentoForm & { id: number }>(`/api/problems/problemi/comments/${pk}/`, data as unknown as plain_object)
export const patch_commento_2 = async (pk: number, data: DeepPartial<T.CommentoForm>) => patch_page<T.CommentoForm & { id: number }>(`/api/problems/shortlist/comments/${pk}/`, data as unknown as plain_object)
export const patch_flag_smistatore = async (pk: number, data: DeepPartial<T.FlagSmistatoreForm>) => patch_page<T.FlagSmistatoreForm & { id: number }>(`/api/problems/problemi/flags/${pk}/`, data as unknown as plain_object)
export const patch_shortlist = async (pk: number, data: DeepPartial<T.ShortlistForm>) => patch_page<T.ShortlistForm & { id: number }>(`/api/problems/shortlist/versione/${pk}/`, data as unknown as plain_object)
export const patch_soluzione_con_risposta = async (pk: number, data: DeepPartial<T.SoluzioneConRispostaForm>) => patch_page<T.SoluzioneConRispostaForm & { id: number }>(`/api/problems/problemi/soluzione/${pk}/`, data as unknown as plain_object)
export const patch_thread_problem_generic_con_risposta = async (pk: number, data: DeepPartial<T.ThreadProblemGenericConRispostaForm>) => patch_page<T.ThreadProblemGenericConRispostaForm & { id: number }>(`/api/problems/problemi/${pk}/`, data as unknown as plain_object)
export const patch_whoami = async (data: DeepPartial<T.WhoamiForm>) => patch_page<T.WhoamiForm & { id: number }>(`/api/whoami/`, data as unknown as plain_object)
export const post_allegato = async (data: T.AllegatoForm) => post_page<T.AllegatoForm & { id: number }>(`/api/problems/allegato/`, data as unknown as plain_object)
export const post_avatar = async (data: T.AvatarForm) => post_page<T.AvatarForm & { id: number }>(`/api/problems/avatar/`, data as unknown as plain_object)
export const post_avatar_serio = async (data: T.AvatarSerioForm) => post_page<T.AvatarSerioForm & { id: number }>(`/api/problems/avatar-serio/`, data as unknown as plain_object)
export const post_bozza_problema = async (data: T.BozzaProblemaForm) => post_page<T.BozzaProblemaForm & { id: number }>(`/api/problems/problemi/bozze/`, data as unknown as plain_object)
export const post_build_shortlist_detail_build_tex = async (pk: number, data: T.BuildShortlistForm) => post_page<T.BuildShortlistForm & { id: number }>(`/api/problems/shortlist/${pk}/build_tex/`, data as unknown as plain_object)
export const post_commento = async (data: T.CommentoForm) => post_page<T.CommentoForm & { id: number }>(`/api/problems/problemi/comments/`, data as unknown as plain_object)
export const post_commento_2 = async (data: T.CommentoForm) => post_page<T.CommentoForm & { id: number }>(`/api/problems/shortlist/comments/`, data as unknown as plain_object)
export const post_flag_smistatore = async (data: T.FlagSmistatoreForm) => post_page<T.FlagSmistatoreForm & { id: number }>(`/api/problems/problemi/flags/`, data as unknown as plain_object)
export const post_linter_input_lint = async (data: T.LinterInputForm) => post_page<T.LinterInputForm & { id: number }>(`/api/problems/lint/`, data as unknown as plain_object)
export const post_risposta_con_risposta = async (data: T.RispostaConRispostaForm) => post_page<T.RispostaConRispostaForm & { id: number }>(`/api/problems/problemi/risposta/`, data as unknown as plain_object)
export const post_soluzione_con_risposta = async (data: T.SoluzioneConRispostaForm) => post_page<T.SoluzioneConRispostaForm & { id: number }>(`/api/problems/problemi/soluzione/`, data as unknown as plain_object)
export const post_tex_compilation_response_detail_build_tex_problema = async (pk: number, data: T.TexCompilationResponseForm) => post_page<T.TexCompilationResponseForm & { id: number }>(`/api/problems/problemi/${pk}/build_tex_problema/`, data as unknown as plain_object)
export const post_tex_compilation_response_detail_build_tex_soluzione = async (pk: number, data: T.TexCompilationResponseForm) => post_page<T.TexCompilationResponseForm & { id: number }>(`/api/problems/problemi/${pk}/build_tex_soluzione/`, data as unknown as plain_object)
export const post_thread_problem_generic_con_risposta = async (data: T.ThreadProblemGenericConRispostaForm) => post_page<T.ThreadProblemGenericConRispostaForm & { id: number }>(`/api/problems/problemi/`, data as unknown as plain_object)
export const post_thread_shortlist = async (data: T.ThreadShortlistForm) => post_page<T.ThreadShortlistForm & { id: number }>(`/api/problems/shortlist/`, data as unknown as plain_object)
export const post_thread_shortlist_detail_assegna_evento = async (pk: number, data: T.ThreadShortlistForm) => post_page<T.ThreadShortlistForm & { id: number }>(`/api/problems/shortlist/${pk}/assegna_evento/`, data as unknown as plain_object)
export const post_voto_bellezza = async (data: T.VotoBellezzaForm) => post_page<T.VotoBellezzaForm & { id: number }>(`/api/problems/problemi/voto-bellezza/`, data as unknown as plain_object)
export const post_voto_difficolta = async (data: T.VotoDifficoltaForm) => post_page<T.VotoDifficoltaForm & { id: number }>(`/api/problems/problemi/voto-difficolta/`, data as unknown as plain_object)
export const put_assegna_problema = async (pk: number, data: T.AssegnaProblemaForm) => put_page<T.AssegnaProblemaForm & { id: number }>(`/api/problems/problemi/assegna/${pk}/`, data as unknown as plain_object)
export const put_bozza_problema = async (pk: number, data: T.BozzaProblemaForm) => put_page<T.BozzaProblemaForm & { id: number }>(`/api/problems/problemi/bozze/${pk}/`, data as unknown as plain_object)
export const put_commento = async (pk: number, data: T.CommentoForm) => put_page<T.CommentoForm & { id: number }>(`/api/problems/problemi/comments/${pk}/`, data as unknown as plain_object)
export const put_commento_2 = async (pk: number, data: T.CommentoForm) => put_page<T.CommentoForm & { id: number }>(`/api/problems/shortlist/comments/${pk}/`, data as unknown as plain_object)
export const put_flag_smistatore = async (pk: number, data: T.FlagSmistatoreForm) => put_page<T.FlagSmistatoreForm & { id: number }>(`/api/problems/problemi/flags/${pk}/`, data as unknown as plain_object)
export const put_shortlist = async (pk: number, data: T.ShortlistForm) => put_page<T.ShortlistForm & { id: number }>(`/api/problems/shortlist/versione/${pk}/`, data as unknown as plain_object)
export const put_soluzione_con_risposta = async (pk: number, data: T.SoluzioneConRispostaForm) => put_page<T.SoluzioneConRispostaForm & { id: number }>(`/api/problems/problemi/soluzione/${pk}/`, data as unknown as plain_object)
export const put_thread_problem_generic_con_risposta = async (pk: number, data: T.ThreadProblemGenericConRispostaForm) => put_page<T.ThreadProblemGenericConRispostaForm & { id: number }>(`/api/problems/problemi/${pk}/`, data as unknown as plain_object)